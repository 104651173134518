<template>
    <div id="div">
        <div id="contact">
            <img src="./images/logo2.png">
            <!-- <p>公司地址：内蒙古自治区兴安盟科尔沁右翼前旗科尔沁镇府前街南金界路西科右前旗双创产业园三楼</p> -->
            <p>Copyright&copy;恒臻科技（大连）有限责任公司</p>
            <p><a style="color: white;" href="https://beian.miit.gov.cn" target="blank">许可证号：辽ICP备2021006853号</a></p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
img{
  width: 100px;
  height: 80px;
}
*{
  margin: 0;
  padding: 0;
}
#div{
    width: 100%;
    height: 300px;
    background: #05355c;
}
#contact{
    margin: 0 auto;
    width: 1100px;
    height: 150px;
    text-align: center;
    color: white;
    font-size: 18px;

    padding-top: 34px;
}
#contact p{
    margin-top: 8px;
}
</style>
